import { ConfigureProps } from "react-instantsearch";

export const PRODUCT_INDEX_EN = "product";
export const CARDS_PER_PAGE = 24;
export const CARDS_PER_ROW = 3;
export const PRE_QUERY = "pre-query";
export const ALL_AVAILABLE_FACETS = [
  "availability",
  "level",
  "language",
  "partner",
  "program_type",
  "learning_type",
  "skills.skill",
  "subject",
];
export const RESULT_TYPE_NONE = "none";
export const RESULT_TYPE_COURSE = "course";
export const RESULT_TYPE_PROGRAM = "program";
export const RESULT_TYPE_EXECUTIVE_EDUCATION = "executive-education";
export const RESULT_TYPE_BOOT_CAMP = "boot-camp";
export const RESULT_TYPE_DEGREE_PROGRAM = "degree-program";
export const RESULT_TYPES = [
  RESULT_TYPE_COURSE,
  RESULT_TYPE_PROGRAM,
  RESULT_TYPE_BOOT_CAMP,
  RESULT_TYPE_DEGREE_PROGRAM,
  RESULT_TYPE_EXECUTIVE_EDUCATION,
];
export const COURSE = "Course";
export const PROGRAM = "Program";
export const EXECUTIVE_EDUCATION = "Executive Education";
export const TWOU_DEGREE = "2U Degree";

export const PRODUCT_TYPES = [
  COURSE,
  PROGRAM,
  EXECUTIVE_EDUCATION,
  TWOU_DEGREE,
];

export const PRODUCT_TYPES_TO_SHOW = [
  COURSE,
  EXECUTIVE_EDUCATION,
  PROGRAM,
  TWOU_DEGREE,
];

export const PRODUCT_TYPE_TITLE_MAP: { [key: string]: string } = {
  [COURSE]: "Courses",
  [PROGRAM]: "Programs",
  [EXECUTIVE_EDUCATION]: "Executive education courses",
  [TWOU_DEGREE]: "Degree programs",
};

export const PRODUCT_TO_RESULT_TYPE_MAP: { [key: string]: string } = {
  [COURSE]: RESULT_TYPE_COURSE,
  [PROGRAM]: RESULT_TYPE_PROGRAM,
  [EXECUTIVE_EDUCATION]: RESULT_TYPE_EXECUTIVE_EDUCATION,
  [TWOU_DEGREE]: RESULT_TYPE_DEGREE_PROGRAM,
};

export const RESULT_PRODUCT_TYPE_MAP: { [key: string]: string } = {
  [RESULT_TYPE_COURSE]: COURSE,
  [RESULT_TYPE_PROGRAM]: PROGRAM,
  [RESULT_TYPE_EXECUTIVE_EDUCATION]: EXECUTIVE_EDUCATION,
  [RESULT_TYPE_DEGREE_PROGRAM]: TWOU_DEGREE,
};

export const DEFAULT_SEARCH_CONFIG: ConfigureProps = {
  hitsPerPage: CARDS_PER_PAGE,
  disjunctiveFacets: ALL_AVAILABLE_FACETS,
  facets: ["product"],
  clickAnalytics: true,
};

export const QUERY_URL_PARAM = "q";
export const RESULT_TYPE_URL_PARAM = "tab";
export const RESULT_PAGE_URL_PARAM = "page";

export const FILTER_CATEGORIES = [
  "subject",
  "skills.skill",
  "partner",
  "learning_type",
  "level",
  "language",
  "availability",
];

export const FILTER_CATEGORIES_NAMES_MAP: {
  [key: string]: string;
} = {
  subject: "Subject",
  "skills.skill": "Skills",
  partner: "School & Partner",
  learning_type: "Learning Type",
  level: "Level",
  language: "Language",
  availability: "Availability",
};

export const REFINEMENT_EXCLUDED_ATTRIBUTES = ["product", "query"];

export const LEARNING_TYPE_FILTER_SUBTITLE: {
  [key: string]: string;
} = {
  Course: "Courses to upskill in your field",
  "Executive Education": "Cohort-based programs in career-critical skills",
  Masters: "Online graduate degrees taught by expert faculty",
  Bachelors: "Online undergraduate degrees taught by expert faculty",
  MicroMasters: "Paths to graduate degrees",
  MicroBachelors: "Paths to undergraduate degrees",
  "Professional Certificate": "Curated series of courses in high-demand fields",
  XSeries:
    "Curated series of courses to develop deep knowledge in popular subject",
};

export const VALID_SEARCH_URL_PARAMS = [
  QUERY_URL_PARAM,
  RESULT_TYPE_URL_PARAM,
  ...FILTER_CATEGORIES,
];

export const PLACEHOLDER_FILTER_COUNT = 7;
export const PLACEHOLDER_CARD_COUNT = 6;
export const FACET_FETCH_LIMIT = 100;
export const FACET_SHOW_LIMIT = 5;
