import React, { FC, ReactNode } from "react";
import parse from "html-react-parser";

import { PRODUCT_INDEX_EN } from "@/components/Search/constants";
import { FormatSubType, FormatType, NO_BRAND } from "@/constants/tagular/main";
import useProductEvents from "@/hooks/eventing/useProductEvents";
import { autocompleteSuggestionClickEvent } from "@/lib/segment";
import convertUrlToRelative from "@/utils/convertUrlToRelative";
import { translateVariant } from "@/utils/tagular/dataTranslationMatrices";
import {
  getTagularSubject,
  hyphenateForTagular,
  setCorrelationId,
} from "@/utils/tagular/helpers";

import { Hit } from "./types";

interface ProductListItemProps {
  hit: Hit;
}

type HitLinkProps = {
  hit: Hit;
  indexName: string;
  queryId?: string;
  children: ReactNode;
};

const HitLink: FC<HitLinkProps> = ({ hit, indexName, queryId, children }) => {
  const position = hit.__position;

  const product = {
    brand: hyphenateForTagular(hit.partner_keys?.[0] || NO_BRAND),
    formatType: FormatType.Search,
    formatSubtype: FormatSubType.SearchResult,
    location: "embedded-search",
    position: position,
    category: getTagularSubject(hit.subject),
    name: hit.title,
    variant: translateVariant(hit.product),
  };

  const { productClicked, correlationId } = useProductEvents({
    product,
  });

  const href = hit.marketing_url
    ? `/${convertUrlToRelative(hit.marketing_url)}`
    : "#";

  const handleClick = () => {
    setCorrelationId(correlationId);

    productClicked();

    autocompleteSuggestionClickEvent({
      link: href,
      objectId: hit.objectID,
      uuid: hit.uuid,
      product: hit.product,
      queryId,
      indexName,
    });
  };

  return (
    <a
      className="no-underline flex items-center"
      onClick={handleClick}
      key={hit.uuid}
      href={href}
    >
      {children}
    </a>
  );
};

/**
 * Renders a search hit component.
 *
 * @param hit - The search hit object.
 * @returns The rendered search hit component.
 */
const ProductListItem = ({ hit }: ProductListItemProps) => {
  return (
    <HitLink hit={hit} indexName={PRODUCT_INDEX_EN} queryId={hit.queryID}>
      <div className="flex flex-wrap gap-1">
        <span>{parse(hit._highlightResult.title.value)}</span>
        <span className="bg-putty-light rounded text-xs text-black py-0.5 px-2">
          {hit.partner_keys[0]}
        </span>
        <span className="bg-primary-500 text-white rounded text-xs py-0.5 px-2">
          {hit.program_type.includes("Masters")
            ? "Masters"
            : hit.product === "OCM"
              ? "Course"
              : hit.product}
        </span>
      </div>
    </HitLink>
  );
};

export default ProductListItem;
